<template>
  <div class="page-prebind-container">
    <van-image
      round
      class="avator"
      :src="headimgurl"
    />
    <div class="nickname">{{ nickname }}</div>
    <p>请先绑定您的云智臻账号</p>
    <van-button round block type="primary" color="#db1e32" class="btn-bind" @click="handleGoBind">
      前往绑定
    </van-button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'PreBind',
  methods: {
    handleGoBind() {
      this.$router.push('/bind');
    }
  },
  computed: {
    ...mapGetters([
      'headimgurl',
      'nickname',
    ]),
  }
}
</script>

<style lang="less">
  @import '@/styles/variables.less';

  .page-prebind-container {
    background: #fff;

    .avator {
      display: block;
      width: 80px;
      height: 80px;
      margin: 20px auto 5px;
    }

    .nickname {
      text-align: center;
      font-weight: 600;
      font-size: 14px;
    }

    p {
      text-align: center;
    }

    .btn-bind {
      width: 80%;
      margin: 50px auto;
    }
  }
</style>
